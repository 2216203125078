// last update 2021/04/15
import $ from 'jquery';

/* inview
--------------------------- */
!(function (a) {
  'function' == typeof define && define.amd
    ? define(['jquery'], a)
    : 'object' == typeof exports
    ? (module.exports = a(require('jquery')))
    : a(jQuery);
})(function (a) {
  function i() {
    var b,
      c,
      d = { height: f.innerHeight, width: f.innerWidth };
    return (
      d.height ||
        ((b = e.compatMode),
        (b || !a.support.boxModel) &&
          ((c = 'CSS1Compat' === b ? g : e.body), (d = { height: c.clientHeight, width: c.clientWidth }))),
      d
    );
  }
  function j() {
    return {
      top: f.pageYOffset || g.scrollTop || e.body.scrollTop,
      left: f.pageXOffset || g.scrollLeft || e.body.scrollLeft,
    };
  }
  function k() {
    if (b.length) {
      var e = 0,
        f = a.map(b, function (a) {
          var b = a.data.selector,
            c = a.$element;
          return b ? c.find(b) : c;
        });
      for (c = c || i(), d = d || j(); e < b.length; e++)
        if (a.contains(g, f[e][0])) {
          var h = a(f[e]),
            k = { height: h[0].offsetHeight, width: h[0].offsetWidth },
            l = h.offset(),
            m = h.data('inview');
          if (!d || !c) return;
          l.top + k.height > d.top && l.top < d.top + c.height && l.left + k.width > d.left && l.left < d.left + c.width
            ? m || h.data('inview', !0).trigger('inview', [!0])
            : m && h.data('inview', !1).trigger('inview', [!1]);
        }
    }
  }
  var c,
    d,
    h,
    b = [],
    e = document,
    f = window,
    g = e.documentElement;
  (a.event.special.inview = {
    add: function (c) {
      b.push({ data: c, $element: a(this), element: this }), !h && b.length && (h = setInterval(k, 250));
    },
    remove: function (a) {
      for (var c = 0; c < b.length; c++) {
        var d = b[c];
        if (d.element === this && d.data.guid === a.guid) {
          b.splice(c, 1);
          break;
        }
      }
      b.length || (clearInterval(h), (h = null));
    },
  }),
    a(f).on('scroll resize scrollstop', function () {
      c = d = null;
    }),
    !g.addEventListener &&
      g.attachEvent &&
      g.attachEvent('onfocusin', function () {
        d = null;
      });
});

//フェードイン
$('.FadeIn').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
  //
  if (isInView) {
    $(this).stop().addClass('FadeIn--Start');
  }
});

//下からフェードイン
$('.FadeIn_Up').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
  //
  if (isInView) {
    $(this).stop().addClass('FadeIn_Up--Start');
  }
});

//左からフェードイン
$('.FadeIn_LeftRight').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
  //
  if (isInView) {
    $(this).stop().addClass('FadeIn_LeftRight--Start');
  }
});

//右からフェードイン
$('.FadeIn_RightLeft').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
  //
  if (isInView) {
    $(this).stop().addClass('FadeIn_RightLeft--Start');
  }
});

//ズームイン
$('.ZoomIn').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
  //
  if (isInView) {
    $(this).stop().addClass('ZoomIn--Start');
  }
});

//色が表示されてから要素が表示される
$('.SlideColor').on('inview', function (c, b, a, d) {
  if (b) {
    $(this).stop().addClass('SlideColor--Start');
  }
});

//ぼかし
$('.Blur').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
  //
  if (isInView) {
    $(this).stop().addClass('Blur--Start');
  }
});

/* others
--------------------------- */

// loading
window.onload = function () {
  // loading
  var body = $('body');
  var loading = $('.p-loading');
  var mv = $('.l-mv');
  body.addClass('loaded');
  loading.addClass('loaded');
  mv.addClass('loaded');

  // 1文字ずつ表示させる(MV)
  // $('.mv__summary, .mv__catch--sub, .mv-common').addClass('start');
  // $('.mv__catch--main').each(function(){
  // 	//一文字ずつ<span>で括る
  // 	$(this).children().addBack().contents().each(function() {
  // 		if (this.nodeType == 3) {
  // 		$(this).replaceWith($(this).text().replace(/(\S)/g, '<span class="pop-txt">$1</span>'));
  // 		}
  // 	});
  // 	$(this).on('inview', function(event, isInView) {
  // 		var activePoint = $(this);
  // 		if (isInView) {　//scroll down
  // 			//一文字ずつ順番に不透明させる
  // 			$(activePoint).addClass('start--txt');
  // 			// $(activePoint).css({'opacity':1}).addClass('start');
  // 			// for (var i = 0; i <= $(activePoint).find('span').length; i++) {
  // 			// 	$(activePoint).find('span').eq(i).delay(60*i).animate({'opacity':1},1000);
  // 			// };
  // 		}
  // 	});
  // });
};
